<template>
  <div class="aboutOurCompany">
    <div class="title"><h2>عــن الشــركــه</h2></div>
    <v-divider></v-divider>
    <v-container>
      <v-row>
        <h4 class="blue--text">
          شركه مياه الشرب والصرف الصحي بأسيوط والوادي الجديد
        </h4>
        <span class="ml-2 mr-2">/</span
        ><span class="gray--text"> عن الشـركـة </span>
      </v-row>
      <v-row class="mt-10">
        <v-col class="subClass" cols="12" sm="12" xs="12" lg="9">
          <div class="fade-in-right">
            <h2>اولاً : قرار إنشاء الشركة</h2>
            <h3>
              <p>
                قرار وزير الاسكان والمرافق ( رقم 95 لسنة 2008 بالترخيص لتأسيس
                شركة تابعة مساهمة مصرية لمياه الشرب والصرف الصحى لمحافظة أسيوط )
                .
              </p>
            </h3>
            <h2>ثانياً :- الأهداف الأستراتيجية للشركة : -</h2>
            <ul>
              <li>
                رفع كفاءة محاور التشغيل والصيانه .
              </li>
              <li>تحسين آليات المحافظة على مصادر المياه للمقاييس المصرية .</li>
              <li>
                الإرتقاء بمستوى أداء المعامل فنياً وإدارياً للمحافظة على جودة
                مياه الشرب ومعالجة الصرف الصحي .
              </li>
              <li>رفع كفاءة العاملين فنيا وإداريا بإستخدام التقنيات الحديثة</li>
              <li>التحقق من مستوى الرضا الوظيفي الحالى للعاملين وتحسينه.</li>
              <li>
                إدخال برنامج التحول الرقمي في الشركة لتحسين مستوى الخدمة وتقديم
                الخدمات الإلكترونية في كافة فروع وإدارات الشركة .
              </li>
            </ul>
          </div>
          <hr class="blue--text ma-6" />
          <h3 class="blue--text pa-2">مجلس الإداره</h3>
          <div>
            <v-simple-table dir="rtl">
              <template v-slot:default>
                <thead>
                  <tr dir="rtl" class="trClass">
                    <th class="text-right">أسم العضـو</th>
                    <th class="text-right">المنصـب</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in adminBoardmembers" :key="item" dir="rtl">
                    <td class="text-right">{{ item.name }}</td>
                    <td class="text-right">{{ item.position }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-col>
        <v-col cols="12" sm="12" xs="12" lg="3">
          <Link></Link>
        </v-col>
        <v-col cols="12" sm="12" xs="12" lg="12">
          <div class="wordOfBoss pa-2" v-animate-onscroll="'fade-in-right'">
            <h3 class="blue--text">كلمه رئيس مجلس الإداره</h3>
            <h4>
              <p class="gray--text pa-5">
                الماء هو القلب النابض للحياة، وهو المكون الأساسي لكل ما هو موجود
                على سطح الأرض، تصديقاً لقول الله تعالى (وَجَعَلْنَا مِنَ الْمَاء
                كُلَّ شَيْءٍ حَيٍّ)، فلولا الماء لانعدمت الحياة، فوجوده هو السبب
                الرئيسي لوجود الحياة، ومن نعم الله علينا أن وهب مصرنا الحبيبة
                نهر النيل العظيم الذى يمثل شريان الحياة، الأمر الذى يدعو إلى
                ضرورة أن نمنحها قيمه عظيمة تكمن وراء مكانتها الحقيقية التى
                تستلزم الحفاظ على كل قطرة فيها، وتنفى شتى الإستخدامات والسلوكيات
                الخاطئة فى التعامل معها، وتمكننا من استغلالها الإستغلال الأمثل
                الذى يضمن بقاءها ودوامها، مما يكفل الكسب للجميع.
              </p>
            </h4>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Link from "../../components/link.vue";
export default {
  name: "Anelsherka",
  components: {
    Link,
  },
  data() {
    return {
      ArrayOFAnElsherka: [
        {
          icon: "mdi-arrow-right-drop-circle-outline",
          title: "رساله الشركة",
          img: require("@/assets/Images/7.png"),
        },
        {
          icon: "",
          title: "صفحتنا علي الفيسبوك",
          img: require("@/assets/Images/8.jpg"),
          subText: "صفحتنا",
        },
        {
          icon: "",
          title: "مواقع هامة",
          listofItem: [
            {
              subImportantSite: "بوابه الحكومه المصريه",
              link: "https://www.egypt.gov.eg/arabic/home.aspx",
            },
            {
              subImportantSite: "رئاسه مجلس الوزراء",
              link: "https://www.cabinet.gov.eg/Arabic/Pages/default.aspx",
            },

            {
              subImportantSite: "الشركة القابضة لمياه الشرب و الصرف الصحى",
              link: "https://www.hcww.com.eg/ar/default.aspx",
            },
          ],
        },
      ],
      tops: [
        {
          name: "السيد اللواء أ.ح.م / هشام محمد درة  ",
          number: "رئيس مجلس الادارة والعضو المنتدب",
        },
        {
          name: "السيد اللواء المهندس  / شاكر محمد مصطفي يونس  ",
          number: "السكرتير العام لمحافظ أسيوط - عضواً",
        },
        {
          name: "السيد الأستاذ/ سيد محمود عبدالله ",
          number: "السكرتير العام لمحافظة الوادي الجديد - عضواً",
        },
        {
          name: "السيد الدكتور / محمد زين الدين حافظ",
          number: "وكيل وزارة الصحة - عضواً",
        },
        {
          name: "السيد المهندس/ ناجح فتحي عبد الرحمن ",
          number:
            "رئيس الجهاز التنفيذي للهيئة القومية لمياه الشرب والصرف الصحي بأسيوط - عضواً",
        },
        {
          name: "السيد الأستاذ / عماد محمد حسين",
          number: "عضو مجلس الإدارة بالشركة - بالانتخاب ",
        },
        {
          name: "السيد المهندس / هاني محمد عبد العزيز",
          number: "عضو مجلس الإدارة بالشركة - بالانتخاب ",
        },
      ],
    };
  },
  computed: {
    adminBoardmembers() {
      return this.$store.state.adminBoardmembers;
    },
  },
};
</script>
<style>
.trClass {
  background: rgba(128, 128, 128, 0.165);
}
.wordOfBoss {
  background: rgba(128, 128, 128, 0.165);
  font-family: Arial, Helvetica, sans-serif;
}
</style>
